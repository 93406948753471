<template>
  <v-card>
    <v-card-title class="justify-center">
      <v-row>
        <v-col cols="12" class="text-center">
          <v-btn color="primary" @click="newItem()">Nuevo Rol</v-btn>
        </v-col>
        <!--
        <v-col cols="12" class="text-center">
          <v-btn color="primary" dark class="mb-2" @click="downloadExport()">
            <v-icon left>
              mdi-download
            </v-icon>
            Descargar
          </v-btn>
        </v-col>
        -->
      </v-row>
      <RolAgregar
        v-if="dialog"
        @saved="fetchData()"
        @dialogChange="dialogChange"
        :dialog="dialog"
        :dato="dato"
      />
    </v-card-title>
    <v-data-table :headers="headers" :items="datos" hide-default-footer>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"
          >mdi-information</v-icon
        >
      </template>
    </v-data-table>

    <v-pagination
      v-model="page"
      :length="pages"
      v-on:input="fetchData()"
    ></v-pagination>
  </v-card>
</template>

<script>
//import axios from "axios";
import RolAgregar from "@/components/RolAgregar.vue";
import RoleService from "@/api/RoleService";

export default {
  components: {
    RolAgregar
  },
  data() {
    return {
      dato: {},
      dialog: false,
      url: process.env.VUE_APP_API,
      page: 1,
      pages: 1,
      datos: [],
      search: "",
      headers: [
        { text: "#", align: "start", value: "id" },
        { text: "Nombre", value: "name" },
        { text: "Acción", value: "actions", sortable: false }
      ]
    };
  },
  methods: {
    setDefaultItem() {
      const newDto = {
        name: "",
        permissions: []
      };
      this.dato = newDto;
    },
    dialogChange(value) {
      this.dialog = value;
    },
    editItem(item) {
      this.dato = { ...item };
      this.dialogChange(true);
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    buscar() {
      this.search = this.filtro;
      //this.fetchData();
    },
    fetchData() {
      RoleService.getRoles().then(response => {
        //this.datos = response.data?.items || [];
        this.datos = response;
        this.pages = response.data?.meta?.totalPages || 0;
      });
    }

    //nuevo
  },
  created() {
    this.fetchData();
  }
};
</script>
