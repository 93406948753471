import httpClient from "./http-client";

const ENDPOINT = "/user";

export interface GetUserDto {
  id: number;
  username: string;
  role: string;
}

export interface CreateUserDto {
  id?: number;
  username: string;
  password: string;
  firstname: string;
  lastname: string;
  role: string;
}

class UserService {
  // otras
  getUsers(): Promise<GetUserDto[]> {
    return httpClient.get(`${ENDPOINT}`).then(response => {
      return response.data;
    });
  }

  createUser(dto: CreateUserDto) {
    return httpClient.post(ENDPOINT, dto);
  }
}
export default new UserService();
