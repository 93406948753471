<template>
  <v-dialog v-bind:value="dialog" persistent max-width="600px">
    <v-form>
      <v-card>
        <v-card-title>
          <span class="headline">Crear nuevo</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nombre"
                  v-model="dtoSave.name"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col
                cols="12"
                v-for="(roleRule, index) in rolesRules"
                :key="index"
                class="my-0 py-0"
              >
                <v-checkbox
                  v-model="rolesSelected"
                  :label="roleRule.text"
                  :value="roleRule.value"
                  class="my-0 py-0"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
          <small>* campos requeridos</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close()">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="save()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import RoleService from "@/api/RoleService";
//Wimport DateTimePicker from "@/components/shared/DateTimePicker.vue";
//import "selectize/dist/css/selectize.css";
//import VSelectize from "@isneezy/vue-selectize";
export default {
  components: {
    //DateTimePicker
    //VSelectize
  },
  props: ["dialog", "dato"],
  data() {
    return {
      datetime: new Date(),
      url: process.env.VUE_APP_API,
      page: 1,
      pages: 1,
      datos: [],
      search: "",
      dtoSave: {
        name: "",
        permissions: []
      },
      rolesRules: [
        {
          text: "Administrar usuarios",
          value: { action: "manage", subject: "user" }
        },
        {
          text: "Administrar remisorias",
          value: { action: "manage", subject: "remisoria" }
        },
        {
          text: "Administrar comisiones de remisoria",
          value: { action: "manage", subject: "remisoria.comision" }
        },
        {
          text: "Administrar comisiones",
          value: {
            action: "manage",
            subject: "comision"
          }
        },
        {
          text: "Administrar cobros bmi",
          value: {
            action: "manage",
            subject: "cobros-bmi"
          }
        },
        {
          text: "Administrar agentes",
          value: {
            action: "manage",
            subject: "agente"
          }
        },
        {
          text: "Administrar Pagos",
          value: {
            action: "manage",
            subject: "pago"
          }
        },
        {
          text: "Reporte Cobranzas",
          value: {
            action: "manage",
            subject: "reporte-cobranza"
          }
        },
        {
          text: "Administrar Planes",
          value: {
            action: "manage",
            subject: "plan"
          }
        },
        {
          text: "Administrar Compañias",
          value: {
            action: "manage",
            subject: "compañia"
          }
        }
      ],
      rolesSelected: [],
      //selects
      roles: [
        { text: "Administrador", value: "1" },
        { text: "Contable", value: "2" }
        //{ text: "Adición", value: "3" }
      ]
    };
  },
  methods: {
    dialogChange(value) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    },
    save() {
      const dto = { ...this.dtoSave, permissions: this.rolesSelected };
      RoleService.createRole(dto)
        .then(() => {
          //
          this.$emit("saved", "someValue");
          this.close();
          this.$swal({
            icon: "success",
            title: "Rol",
            text: dto.id ? "Actualizado Exitosamente" : "Creado Exitosamente"
          });
        })
        .catch(error => {
          let mensaje = "Intente más tarde";
          if (error.response && error.response.data) {
            mensaje = error.response.data.message;
          }
          this.$swal({
            icon: "error",
            title: "Rol No Creado/Actualizado",
            text: mensaje
          });
        });
    }
    //nuevo
  },
  created() {
    this.dtoSave = {};
    this.dtoSave = { ...this.dato };
    for (const v of this.dtoSave.permissions) {
      this.rolesSelected.push(v);
    }
  }
};
</script>
