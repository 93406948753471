<template>
  <v-dialog v-bind:value="dialog" persistent max-width="600px">
    <v-form>
      <v-card>
        <v-card-title>
          <span class="headline">Crear nuevo</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Apellido"
                  v-model="dtoSave.lastname"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Nombre"
                  v-model="dtoSave.firstname"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="username"
                  v-model="dtoSave.username"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="password"
                  v-model="dtoSave.password"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  label="Rol"
                  name="rol"
                  :items="roles"
                  v-model="dtoSave.role"
                  item-value="id"
                  item-text="name"
                  return-object
                >
                  <template v-slot:selection="{ item }">
                    {{ item.name }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.name }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
          <small>* campos requeridos</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close()">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="save()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import UserService from "@/api/UserService";
import RoleService from "@/api/RoleService";
//Wimport DateTimePicker from "@/components/shared/DateTimePicker.vue";
//import "selectize/dist/css/selectize.css";
//import VSelectize from "@isneezy/vue-selectize";
export default {
  components: {
    //DateTimePicker
    //VSelectize
  },
  props: ["dialog", "dato"],
  data() {
    return {
      datetime: new Date(),
      url: process.env.VUE_APP_API,
      page: 1,
      pages: 1,
      datos: [],
      search: "",
      dtoSave: {
        username: "",
        password: "",
        firstname: "",
        lastname: "",
        role: ""
      },
      //selects
      roles: [
        //{ text: "Administrador", value: "1" },
        //{ text: "Contable", value: "2" }
        //{ text: "Adición", value: "3" }
      ]
    };
  },
  methods: {
    dialogChange(value) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    },
    save() {
      const dto = { ...this.dtoSave };
      UserService.createUser(dto)
        .then(() => {
          //
          this.$emit("saved", "someValue");
          this.close();
          this.$swal({
            icon: "success",
            title: "Usuario",
            text: dto.id ? "Actualizado Exitosamente" : "Creado Exitosamente"
          });
        })
        .catch(error => {
          let mensaje = "Intente más tarde";
          if (error.response && error.response.data) {
            mensaje = error.response.data.message;
          }
          this.$swal({
            icon: "error",
            title: "Usuario No Creado/Actualizado",
            text: mensaje
          });
        });
    },
    //nuevo
    getRoles() {
      RoleService.getRoles().then(response => {
        this.roles = response;
      });
    }
  },
  created() {
    this.getRoles();
    this.dtoSave = {};
    this.dtoSave = { ...this.dato };
  }
};
</script>
